import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Grid, Cell } from '@trbl/react-css-grid';
import LinkList from './LinkList';
import Card from '../../../../components/Card';

const MenuList = React.memo(() => {
  const { services, machines } = useStaticQuery(graphql`
    query menuData {
      services: allFile(filter: {sourceInstanceName: {eq: "services"}}) {
        nodes {
          childMarkdownRemark {
            fields {
              slug
            }
            frontmatter {
              title
              photo {
                childImageSharp {
                  fluid(maxWidth: 300, maxHeight: 188, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      machines: allFile(filter: {sourceInstanceName: {eq: "machines"}}) {
        nodes {
          childMarkdownRemark {
            fields {
              slug
            }
            frontmatter {
              title
              photo {
                childImageSharp {
                  fluid(maxWidth: 300, maxHeight: 188, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      machinePlaceholder: file(relativePath: {eq: "cnc-mill.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 188, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      servicePlaceholder: file(relativePath: {eq: "welding.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 188, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <div className="wmfab__menu-list">
      <div className="wmfab__menu-list--links">
        <Grid className="wmfab__menu-list-top-links">
          <Cell
            hStart={5}
            hSpan={8}
            hStartL={4}
            hSpanL={10}
            hStartM={3}
            hSpanM={12}
          >
            <ul className="wmfab__menu-list--top-links-list">
              <LinkList />
            </ul>
          </Cell>
        </Grid>
        <Grid
          className="wmfab__menu-list--link-card-list"
        >
          <Cell
            hStart={3}
            hSpan={6}
            hSpanL={6}
            hSpanM={12}
            className="wmfab__menu-list--category"
          >
            <Link to="/services/">
              <h3>
                Services
              </h3>
            </Link>
            <Grid>
              {services.nodes.map(({
                childMarkdownRemark: {
                  fields: { slug },
                  frontmatter: {
                    title,
                    photo,
                  },
                },
              }) => {
                return (
                  <Cell
                    hSpan={2}
                    hSpanL={3}
                    hSpanM={4}
                    hSpanS={12}
                    key={title}
                  >
                    <Card
                      name={title}
                      slug={`/services${slug}`}
                      image={photo}
                    />
                  </Cell>
                )
              })}
            </Grid>
          </Cell>
          <Cell
            hSpan={6}
            hStartM={3}
            hSpanM={12}
            className="wmfab__menu-list--category"
          >
            <Link to="/machines/">
              <h3>
                Machines
              </h3>
            </Link>
            <Grid>
            {machines.nodes.map(({
                childMarkdownRemark: {
                  fields: { slug },
                  frontmatter: {
                    title,
                    photo,
                  },
                },
              }) => {
                return (
                  <Cell
                    hSpan={2}
                    hSpanL={3}
                    hSpanM={4}
                    hSpanS={12}
                    key={title}
                  >
                    <Card
                      name={title}
                      slug={`/machines${slug}`}
                      image={photo}
                    />
                  </Cell>
                )
              })}
            </Grid>
          </Cell>
        </Grid>
        <Grid>
        </Grid>
      </div>
    </div>
  )
});

export default MenuList;
